//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import {mapActions, mapGetters} from "vuex";
import {adminCodes} from "@/enums/adminSettings";
export default {
  name: 'RegisterCard',
  components: {LabeledControlLayout},
  data() {
    return {
      createNewCustomer: true,
      registerForm: {
        data: {
          company: '',
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
        },
        emailRules: [
          (v) => !!v || 'Required',
          (v) => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
        ],
        rules: {
          required: (value) => !!value || 'Required',
          min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        },
        showPassword: false,
        valid: false,
      },
    }
  },
  computed:{
    //...mapGetters('backoffice/settings', ['adminSettingByCode']),
   /* createNewCustomer(){
      const createNewCustomer = this.adminSettingByCode(
        adminCodes.CREATE_CUSTOMER_FOR_NEW_REGISTRATION
      )
      return createNewCustomer && createNewCustomer.value === 'true'
    }*/
  },
  methods: {
    //...mapActions('backoffice/settings', ['fetchAdminSettingByCode']),
    async register() {
      if (this.$refs.registerForm.validate()) {
        try {
          const response = await this.$axios.post('auth/register', {
            Email: this.registerForm.data.email,
            Company: this.registerForm.data.company,
            FirstName: this.registerForm.data.firstName,
            LastName: this.registerForm.data.lastName,
            Phone: this.registerForm.data.phone,
          })

          if (response.status === 200) {
            if(this.createNewCustomer){
              this.$toast.success('User succesfully registered!')
            }else{
              this.$toast.success('Your request was successfully sent!')
            }

            this.$emit('cancelHandler')
          } else {
            this.$toast.error(
              'There was an error registering the user! Error code: ' +
                response.status
            )
          }
        } catch (err) {
          this.$toast.error('There was an error registering the user!')
        }
      }
    },
    cancel() {
      this.$emit('cancelHandler')
    },
  },
  async mounted() {
   /* const createNewCustomerSetting = await this.fetchAdminSettingByCode(
      adminCodes.CREATE_CUSTOMER_FOR_NEW_REGISTRATION
    )
    this.createNewCustomer = createNewCustomerSetting && createNewCustomerSetting.value === 'true'*/
  }
}
