//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LabeledControlLayout',
  props: {
    format: {
      required: false,
      type: String,
    },
  },
}
