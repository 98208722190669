//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import labeledControlLayout from "@/components/shared/labeled-control-layout";
import {adminCodes} from "@/enums/adminSettings";
export default {
  name: "pin-login-keyboard",
  components: {labeledControlLayout},
  data() {
    return {
      keyboardButtons: [[1,2,3],[4,5,6],[7,8,9],['delete-all',0,'back']],
      keyboardPinInput: '',
    }
  },
  methods: {
    handlePinKeyboardClick(buttonPressed) {
      if(typeof buttonPressed === 'number') {
        this.keyboardPinInput+=buttonPressed
      }
      else {
        if(buttonPressed === 'back' && this.keyboardPinInput.length) {
          this.keyboardPinInput = this.keyboardPinInput.substring(0, this.keyboardPinInput.length-1)
        }
        if(buttonPressed === 'delete-all') {
          this.keyboardPinInput = ''
        }
      }
    },
    emitPin() {
      this.$emit('pinEmitted',this.keyboardPinInput)
      this.keyboardPinInput = ''
      this.$emit('hideKeyboard')
    }
  },

}
