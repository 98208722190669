//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SecondaryButton from "@/components/shared/secondary-button";
import primaryButton from "@/components/shared/primary-button";
export default {
  name: "alert-dialog",
  props:{
    message:{
      required: true,
      type: String,
    },
    confirmText:{
      required: true,
      type: String,
    }
  },
  components: {
    SecondaryButton,
    primaryButton
  }

}
