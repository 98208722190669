export const pinMixin = {
  computed: {},
  methods: {
    focusOnNextPINField(pinValue, maxLength = 4) {
      if (pinValue.length < maxLength ) {
        this.$refs.otpInput.$refs.input[pinValue.length].focus()
      }
    },
  },
}
