//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SecondaryButton',
  props: {
    text: {
      required: true,
      type: "",
    },
    disabled: {
      required: false,
      type: Boolean
    },
    withBackground:{
      required: false,
      type: Boolean
    },
    icon:{
      required: false,
      type: String,
    },
    height:{
      required: false,
      type: Number,
    }
  },
}
